import React from "react"
import { Container } from "react-bootstrap"

const ContentTeaser = ({ title1, description1, title2, description2, title3, description3, additionalClass }) => (
  <section className={`component infoBox mb-5 position-relative ${additionalClass}`}>
    <Container fluid className="py-5">
      <div className="row">
        <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
          {/* <FontAwesomeIcon icon={faCheck} size="3x" /> */}
          <h2 className="h4 mt-4 mb-4">{title1}</h2>
          <p>{description1}</p>
        </div>
        <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
          {/* <FontAwesomeIcon icon={faArrowsAltH} size="3x" /> */}
          <h2 className="h4 mt-4 mb-4">{title2}</h2>
          <p>{description2}</p>
        </div>
        <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
          {/* <FontAwesomeIcon icon={faCommentDots} size="3x" /> */}
          <h2 className="h4 mt-4 mb-4">{title3}</h2>
          <p>{description3}</p>
        </div>
      </div>
    </Container>
  </section>
)

export default ContentTeaser